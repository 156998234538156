import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { applicationsModule } from '@/store/modules/applications';
import Application from '@/models/Application';

@Component
export default class ApplicationSwitch extends Vue {
    public async mounted(): Promise<void> {
        await applicationsModule.fetchApplicationsIfNeeded();
        await applicationsModule.setActiveApplication(applicationsModule.applications[0]);
    }

    public get activeApplication(): Application {
        return new Application(applicationsModule.activeApplication);
    }

    public get applications(): Application[] {
        return applicationsModule.applications;
    }

    public async switchTo(application: Application): Promise<void> {
        let name = this.$route.name;
        if (name === 'error' || name === 'home') {
            name = 'application';
        }

        await applicationsModule.setActiveApplication(application);
        this.$router.push({ name, params: { applicationId: application.applicationId } });
    }
}
