import { Component } from 'vue-property-decorator';
import { loginService, $router } from './main';
import PageRender from './models/PageRender';
import { LoginService } from './services/loginService';
import { applicationsModule } from './store/modules/applications';
import { profileModule } from './store/modules/profile';

@Component
export default class AppComponent extends PageRender {
    public loginService: LoginService = loginService;

    public async created(): Promise<void> {
        await profileModule.init();
        await this.initApp();
    }

    public showContent(): boolean {
        return profileModule.profileLoaded;
    }

    public showTopBar(): boolean {
        return profileModule.profileLoaded;
    }

    public showNav(): boolean {
        return profileModule.profileLoaded;
    }

    public get loggedIn(): Promise<boolean> {
        return loginService.isLoggedIn();
    }

    private async initApp() {
        const user = await loginService.getUser();
        await applicationsModule.fetchApplicationsIfNeeded();
        if (applicationsModule.applications[0]) {
            await applicationsModule.setActiveApplication(applicationsModule.applications[0]);
        }

        if (user == null || user.expired) {
            if (this.$route.name !== 'login') {
                await $router.push({ name: 'login', query: { returnUrl: this.$route.fullPath } });
            }
            return;
        }

        const returnUrl = this.$route.query.redirectUrl;

        if ((!returnUrl && applicationsModule.applications.length) || applicationsModule.applications.length) {
            return this.$router.push({
                name: 'notifications',
                params: {
                    applicationId: applicationsModule.activeApplication.applicationId.toString(),
                },
            });
        }

        const url = returnUrl.toString();
        const route = this.$router.resolve(url);

        if (!route) {
            return this.$router.push({ name: 'app-error' });
        }
        return this.$router.push({ name: route.route.name, params: route.route.params });
    }
}
