import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import { navigationHelper } from '@/main';
import { profileModule } from '@/store/modules/profile';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Login.vue'),
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: 'menu' */ '../views/Menu.vue'),
        children: [
            {
                name: 'home',
                path: '/',
                component: () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
                meta: { title: 'Home' },
            },
            {
                name: 'application',
                path: '/:applicationId',
                component: () => import(/* webpackChunkName: "home" */ '@/views/application/application.vue'),
                meta: { title: 'Home' },
            },
            {
                name: 'notifications',
                path: '/:applicationId/notifications',
                component: () => import(/* webpackChunkName: "home" */ '@/views/notifications/notifications.vue'),
                meta: { title: 'Home' },
            },
            {
                name: 'notification',
                path: '/:applicationId/notifications/:notificationId',
                component: () => import(/* webpackChunkName: "home" */ '@/views/notifications/notification.vue'),
                meta: { title: 'Home' },
            },
            {
                name: 'app-error',
                path: '/error',
                component: () => import(/* webpackChunkName: 'about' */ '@/views/error/error.vue'),
                meta: { title: 'Error Page', scope: 'app', section: 'error' },
            },
            {
                name: 'not-found',
                path: '*',
                component: () => import(/* webpackChunkName: 'about' */ '@/views/notfound/notfound.vue'),
                meta: { title: 'Woops!?' },
            },
            // {
            //     name: 'callback',
            //     path: '/callback',
            //     component: Callback,
            //     meta: { title: 'Woops!?' },
            // },
        ],
    },
];

const onRouteFinished = (to: Route) => {
    const meta = to.meta || {};
    const params = to.params || {};

    let title = 'Bright Common Portal';

    if (params.teamKey) {
        title += ` - ${params.teamKey}`;
    }

    if (meta.title) {
        title += ` - ${meta.title}`;
    }

    Vue.nextTick(() => {
        document.title = title;
        navigationHelper.hideNavigation();
        navigationHelper.loadRouteMeta(meta, params);
    });
};

const ifAuthenticated = async (to: Route, _from: Route, next: any) => {
    const anonymousRoutes = ['login', 'app-error', 'not-found', 'invite'];
    await profileModule.init();
    const loggedIn = profileModule.isLoggedIn;
    const matchedRoute = to.matched.length > 0 ? to.matched[to.matched.length - 1] : to;
    const matchedRouteName = matchedRoute && matchedRoute.name ? matchedRoute.name : '';

    if (anonymousRoutes.indexOf(matchedRouteName) > -1 || loggedIn) {
        next();
        return;
    }

    next(`/login?redirectUrl=${to.fullPath}`);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(ifAuthenticated);
router.afterEach(onRouteFinished);

export default router;
