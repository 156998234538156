import { profileModule } from '@/store/modules/profile';
import { UserManager, WebStorageStateStore, User } from 'oidc-client';
import Vue from 'vue';

export class LoginService {
    private userManager: UserManager;

    constructor() {
        const STS_DOMAIN: string = `${Vue.$env().identityEndpoint}`;

        const settings: any = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: STS_DOMAIN,
            client_id: 'bright-common-portal',
            redirect_uri: `${Vue.$env().portalUrl}callback.html`,
            automaticSilentRenew: true,
            silent_redirect_uri: `${Vue.$env().portalUrl}/silent-renew.html`,
            response_type: 'code',
            scope: 'openid profile roles email common-notifications-management.api directory.all',
            post_logout_redirect_uri: `${Vue.$env().portalUrl}login`,
            filterProtocolClaims: true,
        };

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserLoaded(async (user) => {
            profileModule.setUser(user);
        });

        this.userManager.events.addSilentRenewError((error) => {
            console.log(error);
            console.error('KAPOT');
            // custom logic here
        });

        this.userManager.events.addUserSignedOut(() => {
            console.log('user signed out');
            localStorage.removeItem(`oidc.user:${Vue.$env().identityEndpoint}:bright-common-portal`);
            // window.location.href = '/';
        });
    }

    public async isLoggedIn(): Promise<boolean> {
        const user = await this.getUser();
        const loggedIn = user != null && !user.expired;
        return loggedIn;
    }

    public async getUser(): Promise<User> {
        return await this.userManager.getUser();
    }

    public async login(): Promise<void> {
        await this.clearStaleState();

        await this.userManager.signinRedirect();
    }

    public async accessTokenExpired(): Promise<boolean> {
        const user = await this.getUser();
        const accessTokenExpired = user != null && user.expired;
        return accessTokenExpired;
    }

    public async logout(): Promise<void> {
        await this.userManager.signoutRedirect({ post_logout_redirect_uri: `${Vue.$env().portalUrl}login` });
    }

    public async refreshToken(): Promise<any> {
        return await this.userManager.signinSilent().catch(async (error) => {
            console.error(error);
            await this.logout();
            localStorage.removeItem(`oidc.user:${Vue.$env().identityEndpoint}:bright-common-portal`);
            window.location.href = '/';
        });
    }

    public getAccessToken(): Promise<string> {
        return this.userManager.getUser().then((data: any) => {
            return data.access_token;
        });
    }

    public async clearStaleState(): Promise<void> {
        await this.userManager.clearStaleState();
    }
}
