import PageRender from './PageRender';

export default class BasePage extends PageRender {
    public siteKey: string = '';
    public mode: string = '';
    public isLoaded: boolean = false;
    public editMode: boolean = false;

    public checkMode(): void {
        switch (this.mode) {
            case 'edit':
                this.editMode = true;
                break;
            default:
                this.editMode = false;
                break;
        }
    }

    public edit(): void {
        this.editMode = true;
    }

    public cancelEdit(): void {
        this.editMode = false;
    }
}
