export default class Application {
    public applicationId: string;
    public name: string;

    constructor(obj?: Partial<Application>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
