import { Component, Watch } from 'vue-property-decorator';
import { loginService, navigationHelper } from '@/main';
import { Route } from 'vue-router';
import { BModal } from 'bootstrap-vue';
import ReleaseNotes from '../../components/release-notes/release-notes.vue';
import PageRender from '@/models/PageRender';
import { LoginService } from '@/services/loginService';

@Component({
    components: {
        ReleaseNotes,
    },
})
export default class NavMenuComponent extends PageRender {
    public isMe: boolean = false;
    public isTeam: boolean = false;
    public isEco: boolean = false;
    public show: boolean = false;
    public loginService: LoginService = loginService;

    public $refs!: {
        versionNotesModal: BModal;
    };

    @Watch('$route', { immediate: true, deep: true })
    public onRouteChanged() {
        this.setScope();
    }

    public showVersionModal() {
        this.$refs.versionNotesModal.show();
    }

    public setScope(): void {
        this.isMe = this.$route.path.indexOf('/me/') > -1 || this.$route.path === '/';
    }

    public toggleSubMenu() {
        navigationHelper.toggleNavigation();
    }

    public async logOut() {
        await loginService.logout();
        await this.$router.push({ name: 'login' });
    }

    public goToProfile(): Promise<Route> {
        return this.$router.push({ name: 'me-profile', params: { id: '1' } });
    }

    public showMainNav() {
        return loginService.isLoggedIn();
    }
}
