import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import SharedUI from '@prd/shared-ui';
import './components/index';

import { AxiosHelper } from './helpers/axiosHelper';
import NavigationHelper from './helpers/navigationHelper';

import SideActionsPlugin from './plugin/sideActions/sideActions';

import { LoginService } from './services/loginService';
import Oidc from 'oidc-client';
import { ApplicationService } from './services/applicationService';
import { MeService } from './services/meService';

Vue.use(SharedUI);
Vue.use(SideActionsPlugin);

export const $router = router;
export const axiosHelper = new AxiosHelper({}, {});
export const loginService = new LoginService();
export const meService = new MeService(axiosHelper);
export const applicationService = new ApplicationService(axiosHelper);
export const navigationHelper = new NavigationHelper();

Vue.config.productionTip = false;
Oidc.Log.logger = console;

const globalData = {
    isAuthenticated: false,
    user: '',
    loginService,
    navigationHelper,
};

const globalMethods = {
    async isLoggedIn() {
        return await loginService.isLoggedIn();
    },
    async authenticate(returnPath) {
        const user = await this.$root.getUser(); // see if the user details are in local storage
        if (user) {
            this.isAuthenticated = true;
            this.user = user;
        } else {
            await this.$root.signIn(returnPath);
        }
    },
    async getUser() {
        try {
            const user = await this.mgr.getUser();
            return user;
        } catch (err) {
            console.log(err);
        }
    },
};

new Vue({
    router,
    methods: globalMethods,
    data: globalData,
    store,
    render: (h) => h(App),
}).$mount('#app');
