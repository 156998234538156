import { Component } from 'vue-property-decorator';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { BrightNotificationContent } from '@/models/BrightNotification';
import { applicationService } from '@/main';
import { TestNotificationRequest } from '@/models/TestNotificationRequest';

@Component
export default class SendTestNotificationSideAction extends SideActionBase<SendTestNotificationSideActionParameters> {
    public emailAddress: string = null;

    public notificationId: string = '';
    public applicationId: string = '';
    public variables: string[] = [];
    public params: unknown = {};

    public async created(): Promise<void> {
        this.notificationId = this.$route.params.notificationId;
        this.applicationId = this.$route.params.applicationId;

        this.variables = this.getVariables(this.options.notificationContent.title + this.options.notificationContent.content);

        this.variables.forEach((variable: string) => {
            this.params[variable] = '';
        });
    }

    public async testNotification(content: BrightNotificationContent): Promise<void> {
        this.showPending('Scheduling test notification...');

        await applicationService.testNotification(this.applicationId, this.notificationId, {
            body: content.content,
            subject: content.title,
            masterTemplate: content.template,
            templateParameters: this.params,
            receiverAddress: this.emailAddress,
        } as TestNotificationRequest);

        this.finished();
    }

    public getVariableNames(text: string): string[] {
        const regex = /\{!([a-zA-Z|_0-9]+)\}/g;
        const matches = text.match(regex);
        return [...new Set(matches)];
    }

    public getVariables(text: string): string[] {
        const variables: string[] = [];
        this.getVariableNames(text)?.forEach((match: string) => {
            variables.push(match.substring(2, match.length - 1));
        });
        return variables;
    }
}

export class SendTestNotificationSideActionParameters {
    public notificationContent: BrightNotificationContent;
}
