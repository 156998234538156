import { applicationService } from '@/main';
import Application from '../../models/Application';
import to from 'await-to-js';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';

@Module
class ApplicationModule extends VuexModule {
    private _applications: Application[] = [];
    private _activeApplication: Application = null;
    private _failed: boolean = false;
    private _failedApplication: boolean = false;
    private _loading: boolean = false;

    public get applications() {
        return this._applications;
    }

    public get activeApplication() {
        return this._activeApplication ? this._activeApplication : new Application();
    }

    public get failed() {
        return this._failed;
    }

    public get failedApplication() {
        return this._failedApplication;
    }

    public get loading() {
        return this._loading;
    }

    @Action
    public async fetchApplications() {
        this.SET_LOADING(true);
        const [err, response] = await to(applicationService.getApplications());
        this.SET_LOADING(false);
        this.SET_FAILED(!!err);
        if (!err) {
            return this.SET_APPLICATIONS(response.data);
        }
    }

    @Action
    public async fetchApplicationsIfNeeded() {
        if (!this._applications.length || this._failed) {
            return await this.fetchApplications();
        }
    }

    @Action
    public async setActiveApplication(application: Application) {
        if (this.activeApplication.applicationId !== application.applicationId) {
            this.SET_LOADING(true);
            const [err, response] = await to(applicationService.getApplication(application.applicationId));
            this.SET_LOADING(false);
            this.SET_FAILED_APPLICATION(!!err);
            if (!err) {
                return this.SET_ACTIVE(response.data);
            } else {
                return this.SET_ACTIVE(application);
            }
        }
    }

    @Mutation
    public SET_ACTIVE(payload: Application) {
        this._activeApplication = payload;
    }

    @Mutation
    public CLEAR_APPLICATIONS() {
        this._applications = [];
        this._failed = false;
    }

    @Mutation
    private SET_APPLICATIONS(payload: Application[]) {
        this._applications = payload;
    }

    @Mutation
    private SET_FAILED(payload: boolean) {
        this._failed = payload;
    }

    @Mutation
    private SET_FAILED_APPLICATION(payload: boolean) {
        this._failedApplication = payload;
    }

    @Mutation
    private SET_LOADING(payload: boolean) {
        this._loading = payload;
    }
}

export const applicationsModule = new ApplicationModule({ store, name: 'applications' });
