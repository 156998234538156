import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// tslint:disable-next-line: no-inferred-empty-object-type
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {},
});
