import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VueRouter from 'vue-router';

@Component
export default class GridActiosComponent extends Vue {
    public text: string = '';
    public url: string = '';

    @Prop() public item: any;
    @Prop() public actions: any[];
    @Prop() public orientation: any;

    public propItem: any = {};
    public propActions = [];
    public propOrientation = 'horizontal';
    private router: VueRouter = {} as VueRouter;

    public mounted() {
        if (this.orientation) {
            this.propOrientation = this.orientation;
        }
    }

    public async goToUrl() {
        await this.router.push(this.url);
    }

    public actionValueTemplate(): string {
        return `<span class="icon-more-${this.propOrientation}"></span>`;
    }

    public actionTemplate(actionItem): string {
        return `<span class='px-2 ${actionItem.disabled ? 'k-state-disabled' : ''}'>${actionItem.title.toString()}</span>`;
    }

    public onSelect(e): void {
        if (!e.dataItem.disabled) {
            e.dataItem.function(this.item);
        }
    }
}
