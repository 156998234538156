import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';
import { BaseService } from './baseService';
import Application from '@/models/Application';
import { AxiosPromise } from 'axios';
import BrightNotification, { BrightNotificationContent } from '@/models/BrightNotification';
import { TestNotificationRequest } from '../models/TestNotificationRequest';

export class ApplicationService extends BaseService {
    private applicationEndpoint = `${Vue.$env().apiEndpoint}applications`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getApplications(): AxiosPromise<Application[]> {
        return this.get<Application[]>(`${this.applicationEndpoint}`);
    }

    public getApplication(applicationId: string): AxiosPromise<Application> {
        return this.get<Application>(`${this.applicationEndpoint}/:application`, [['application', applicationId]]);
    }

    public getNotifications(applicationId: string): AxiosPromise<BrightNotification[]> {
        return this.get<BrightNotification[]>(`${this.applicationEndpoint}/${applicationId}/notifications`);
    }

    public getNotification(applicationId: string, notificationId: string): AxiosPromise<BrightNotification> {
        return this.get<BrightNotification>(`${this.applicationEndpoint}/${applicationId}/notifications/${notificationId}`);
    }

    public testNotification(applicationId: string, notificationId: string, model: TestNotificationRequest): AxiosPromise<BrightNotification> {
        return this.post<BrightNotification>(`${this.applicationEndpoint}/${applicationId}/notifications/${notificationId}/test-send`, model);
    }

    public updateNotification(content: BrightNotificationContent, applicationId: string, notificationId: string): AxiosPromise<BrightNotification> {
        return this.put<BrightNotification>(
            `${this.applicationEndpoint}/${applicationId}/notifications/${notificationId}/content/${content.id}`,
            content,
        );
    }
}
