export default class NavigationHelper {
    public isNavVisible: boolean = false;
    public section: string = '';
    public scope: string = '';
    public isTeam: boolean = false;
    public isGroup: boolean = false;

    public toggleNavigation() {
        this.isNavVisible = !this.isNavVisible;
    }

    public hideNavigation() {
        this.isNavVisible = false;
    }

    public loadRouteMeta(meta, params) {
        this.section = (meta.section || 'directory');
        this.scope = (meta.scope || 'me');

        this.isTeam = this.scope.indexOf('team') > -1;
        this.isGroup = params && params.groupKey && params.groupKey !== '';
    }
}
