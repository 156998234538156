import Vue from 'vue';

export default class PageRender extends Vue {
    public showValidationErrors(errors: string[]): void {
        const errorsHtml = errors
            .map((item) => {
                return `<li>${item}</li>`;
            })
            .join('');

        // First clean all previous errors
        this.clearNotifications();
        this.$notify({
            title: 'The form is invalid, please correct the following issues:',
            text: `<ul>${errorsHtml}</ul>`,
            type: 'error',
            duration: -1,
        });
    }

    public showFailedResponse(error: string, consoleError: string): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.showError(error);
    }

    public showPending(message: string): void {
        this.$notify({
            title: `${message}`,
            type: 'loading',
            duration: -1,
        });
    }

    public showError(error: string, timeInMs: number = 3000): void {
        this.clearNotifications();

        this.$notify({
            title: 'Oh snap! Something has gone wrong...',
            text: error,
            type: 'error',
            duration: timeInMs,
        });
    }

    public showWarning(message: string): void {
        this.clearNotifications();

        this.$notify({
            title: `${message}`,
            type: 'warning',
            duration: -1,
        });
    }

    public showSuccess(message: string, text?: string): void {
        this.clearNotifications();

        this.$notify({
            title: `${message}`,
            text,
            type: 'success',
        });
    }

    public clearNotifications(): void {
        this.$notify({
            clean: true,
        });
    }

    public clearAndShowSuccess(message: string): void {
        this.clearNotifications();
        this.showSuccess(message);
    }

    public clearAndShowError(error: string, consoleError = null): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.clearNotifications();
        this.showError(error);
    }
}
