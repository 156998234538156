/**
 * Different types of roles used within the application.
 */
export enum UserRoles {
    /**
     * Has global 'superuser' rights.
     */
    SiteAdmin = 'BE_SiteAdmin',

    /**
     * Has rights on a team (excluding groups that belong to that team).
     */
    FinanceEmployee = 'FinanceEmployee',

    /**
     * Has rights on a group.
     */
    Member = 'BE_Member',
}

/**
 * Target specific role definitions for users.
 */
export default class UserRole {
    public id: number;
    public role: UserRoles;
}
