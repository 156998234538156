import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { loginService } from '@/main';
import { NetworkStatus } from '../models/NetworkStatus';

export class AxiosHelper {
    public axiosResponseInterceptor: number;
    private authAxiosInstance: AxiosInstance = {} as AxiosInstance;
    private publicAxiosInstance: AxiosInstance = {} as AxiosInstance;

    constructor(authConfig: AxiosRequestConfig, publicConfig: AxiosRequestConfig) {
        this.authAxiosInstance = axios.create(authConfig);
        this.publicAxiosInstance = axios.create(publicConfig);

        this.authAxiosInstance.interceptors.request.use(
            async (config) => {
                if (!navigator.onLine) {
                    return Promise.reject(NetworkStatus.Offline);
                }

                if (loginService.isLoggedIn()) {
                    const user = await loginService.getUser();

                    if (user && user.expired) {
                        await loginService.refreshToken();
                    }

                    const jwt = user.access_token;
                    this.setAuthHeder(jwt);
                    config.headers.Authorization = `Bearer ${jwt}`;
                }

                return config;
            },
            (error) => {
                // Do something with request error
                console.error('Some error has occured with this request: ', error);
                return Promise.reject(error);
            },
        );
    }

    public getAuthAxiosInstance(): AxiosInstance {
        return this.authAxiosInstance;
    }

    public getPublicAxiosInstance(): AxiosInstance {
        return this.publicAxiosInstance;
    }

    public setAuthHeder(jwt: string): void {
        this.authAxiosInstance.defaults.headers['Authorization'] = 'Bearer ' + jwt;
    }

    public async setup(): Promise<void> {
        const jwt = await loginService.getAccessToken();
        if (loginService.isLoggedIn()) {
            this.setAuthHeder(jwt);
        }
    }
}
