/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from 'vue';
import DatePicker from 'vue2-datepicker';

Vue.component('date-picker', DatePicker);

Vue.component('menu-component', require('@/components/navmenu/navmenu.vue').default);

// Components
Vue.component('top-bar', require('@/components/top-bar/bar.vue').default);
Vue.component('top-bar-breadcrumb', require('@/components/top-bar/breadcrumb.vue').default);
Vue.component('top-bar-scope', require('@/components/top-bar/scope.vue').default);
Vue.component('spinner', require('@/components/spinner/spinner.vue').default);

Vue.component('navigation-application', require('@/components/navigation/application-switch.vue').default);

Vue.component('grid-percentage', require('@/components/grid/grid-percentage.vue').default);
Vue.component('grid-router-link', require('@/components/grid/grid-router-link.vue').default);
Vue.component('grid-actions', require('@/components/grid/grid-actions.vue').default);
Vue.component('grid-html-renderer', require('@/components/grid/grid-html-renderer.vue').default);
Vue.component('generic-search', require('@/components/generic-search/generic-search.vue').default);

Vue.component('send-test-notification-side-action', require('@/components/side-actions/send-test-notification-side-action.vue').default);
