import Component from 'vue-class-component';
import Vue from 'vue';
import { navigationHelper } from '@/main';

@Component
export default class TopBarBreadcrumb extends Vue {
    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }

    public getRouteObject() {
        const tab = this.$route.meta.section === 'directory' ?  'groups' : 'invoices';

        return {
            name: navigationHelper.scope + '-' + navigationHelper.section,
            params: { teamKey: this.$route.params.teamKey, tab, period: this.$route.params.period },
        };
    }
}
